.project-showcase-outer {
    background-color: var(--project-showcase-background);
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    opacity: 0;
    transition: background-color 0.3s linear;
}

.project-showcase-top {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 50%;
    margin-bottom: 25px;
}

.project-showcase-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
}



.carousel-container {
    width: 100%;
    margin-bottom: 10px;
    height: 350px;
}


.project-details-container {
    height: auto;
    width: 95%;
    min-width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* border-top: 1px solid var(--primary); */
    padding: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.project-header-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.project-header-text {
    font-size: 35px;
    margin: 0;
    color: var(--project-showcase-header);
}

.project-links {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.project-links button {
    border: none;
    padding: 5px;
    font-family: 'rubik';
    cursor: pointer;
}

.project-links button:hover {
    opacity: 0.5;
}

.project-description-text {
    font-family: 'rubik';
    color: var(--project-showcase-description);
    font-size: 15px;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}


@media screen and (max-width: 800px) {

    .project-showcase-outer {
        max-height: fit-content !important;
    }

    .project-showcase-top {
        flex-direction: column;
        align-items: center;
    }

    .project-showcase-outer {
        height: fit-content;
        margin-right: 0;
    }
}