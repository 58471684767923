.circle-outer {
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 3px;
    box-shadow: 0px 0px 20px -5px black;
    background-color: white;
    opacity: inherit;
}

.circle-outer svg {
    position: absolute;
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    animation: progress 3s ease-out forwards;
}

.percentage-text {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    box-shadow: inset 0px 0px 20px -10px black;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    min-height: 35px;
    max-height: 35px;
    max-width: 35px;
    border-radius: 50%;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}