#header {
    margin: auto;
    padding-top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--header-background);
    transition: background-color 0.3s linear;
    min-height: 100vh;
    max-height: 100vh;
}

.sean-image-outer {
    width: 400px;
    height: 400px;
    min-width: 400px;
    min-height: 400px;
    border: 2px solid var(--photo-border);
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    position: absolute;
    left: -500px;
}

.sean-image {
    width: 100%;
    height: auto;
    border-radius: 100%;

}

#bio {
    color: var(--text-primary);
    margin-top: 45px;
    max-width: 300px;
    font-size: 16px;
    font-style: italic;
    overflow-y: auto;
    text-align: center;
    position: absolute;
    right: -500px;
    max-height: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'rubik';
    margin-bottom: 15px;
}

.quick-buttons-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    min-height: 45px;
}

.quick-button {
    opacity: 0;
    border: none;
    height: 45px;
    width: 75px;
    max-height: 45px;
    border-radius: 5px;
    margin-right: 15px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.quick-button img {
    height: 100%;
    width: auto;
    background-color: inherit;
}

.quick-button:hover {
    transform: scale(1.1);
    box-shadow: 0px 2px 1px 1px black;
}


#linkedin-quick {
    background-color: #136bc5;
}

#github-quick {
    background-color: #136bc5;
}


#contact-me-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.contact-me-button {
    background-color: #136bc5;
    color: var(--contact-me-text-color);
    font-family: 'Caveat';
    font-size: 35px;
    opacity: 0;
    border: none;
    height: 40px;
    width: 85%;
    max-height: 45px;
    border-radius: 5px;
    margin-right: 15px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.contact-me-button:hover {
    transform: scale(1.1);
    box-shadow: 0px 2px 1px 1px black;
}

@media screen and (max-width: 750px) {
    #header {
        max-height: 105vh;
        min-height: 105vh;
        flex-direction: column;
        align-items: center;
    }

    .sean-image-outer {
        margin-right: 0;
        width: 300px;
        min-width: 300px;
        height: 300px;
        min-height: 300px;
    }

    #bio {
        top: 450px;
        margin-top: 40px;
    }

}