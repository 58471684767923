#about {
    min-height: 60vh;
    max-height: 60vh;
    background-color: var(--about-background);
    padding-top: 75px;
}

.about-sections-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.about-section {
    position: relative;
    bottom: -50px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    margin-right: 10px;
    height: 100%;
    width: auto;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    max-width: 31vw;
}

.about-header {
    color: var(--about-header);
    font-size: 35px;
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
}

.about-text {
    color: var(--about-text);
    font-family: 'rubik';
    font-size: 14px;
}

br {
    display: block;
}




@media screen and (max-width: 750px) {

    #about {
        max-height: none;
        height: 100%;
    }

    .about-sections-container {
        flex-direction: column;
        align-items: center;
    }

    .about-section {
        max-width: 90vw;
    }
}