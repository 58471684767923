#projects {
    background-color: #000000;
    min-height: 100vh;
    min-width: 100%;
    padding-top: 75px;
    padding-bottom: 150px;
}


.projects-container {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 25px;
    padding-right: 25px;

}


.projects-grid {
    /* display: grid;
    row-gap: 25px;
    column-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr)); */

    display: flex;
    flex-direction: column;
    width: 100%;


}

.projects-grid>* {
    max-height: 650px;
    margin-bottom: 100px;

}

.projects-header {
    color: var(--projects-header);
    font-size: 45px;
    opacity: 0;
    position: relative;
}