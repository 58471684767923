body {
  margin: 0;
  background-color: var(--primary);
  font-family: BebasNeue !important;
  transition: color 0.2s linear;
  transition: background-color 1s linear !important;
}



body::-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--primary);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--tertiary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--tertiary);
}


@font-face {
  font-family: 'rubik';
  src: url('./assets/fonts/Rubik-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'BebasNeue';
  src: url('./assets/fonts/BebasNeue-Regular.ttf');
}


@font-face {
  font-family: 'Caveat';
  src: url('./assets/fonts/Caveat-VariableFont_wght.ttf');
}