.skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    opacity: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.skill-name-text {
    font-family: 'Rubik';
    margin: 5px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
}