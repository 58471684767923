#footer {
    min-height: 100px;
    min-width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    box-shadow: 0px 0px 5px 0px var(--secondary);
}

.copyright {
    margin: 0;
    color: white;
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-family: 'Caveat';
}

.thanks {
    color: white;
    font-family: 'rubik';
    margin: 0;
    text-align: center;
    font-size: 18px;
}

#contact {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: fit-content;
    margin-right: 18px;
    align-items: center;
    justify-content: center;
}

.get-in-touch {
    color: white;
    margin: 0;
    font-size: 20px;
    margin-right: 10px;
    white-space: nowrap;

}

#contact a {
    width: fit-content;
    white-space: nowrap;
    color: var(--tertiary);
    text-align: left;
    text-decoration: underline;
    font-size: 15px;
    font-family: 'rubik';
    margin-left: 5px;
    margin-right: 5px;
}

#contact a:hover {
    color: white;
}

@media screen and (max-width: 760px) {

    .copyright {
        margin: 0;
    }

    #contact {
        margin: 0;
        flex-direction: row;
        justify-content: center;
    }

    #footer {
        flex-direction: column;
    }

    #footer>* {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}


@media screen and (max-width: 480px) {

    #contact {
        align-items: center;
    }

    #contact>* {
        margin-top: 0;
        margin-bottom: 0;
        height: 20px !important;
        overflow: hidden;
    }

    .get-in-touch {
        font-size: 15px;
        margin-right: 2px;
    }

    #contact a {
        font-size: 12px;
        margin-right: 2px;
        margin-left: 2px;
        margin-bottom: 0px;

    }
}