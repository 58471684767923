.image-carousel {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
}

.image-carousel-button {
    position: relative;
    z-index: 2;
    max-height: 100%;
    height: 100%;
    width: 75px;
    background-color: var(--project-showcase-button-background);
    transition: all 0.3s linear;
    cursor: pointer;
    border: none;
}

.image-carousel-button img {
    height: 25px;
    width: auto;
}

.image-carousel-button:hover {
    background-color: rgba(83, 82, 82, 0.5);
}

.image-carousel-button:disabled {
    cursor: default;
}

.image-carousel-button:disabled img {
    display: none;
}

.image-carousel-button:disabled:hover {
    cursor: default;
    background-color: rgba(0, 0, 0, 0.8);
}



.viewport-image-container {
    min-width: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    position: relative;
}

.carousel-viewport {
    background-color: transparent;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.carousel-viewport img {
    width: 100%;
    height: 100%;
    opacity: 1;
    object-fit: contain
}

.btn-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.btn-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

}


.onscreen {
    z-index: 1;
}

.offscreen-left {
    left: -100%;
}

.shade {
    opacity: 1;
    z-index: 2;
    min-height: 500%;
    min-width: 500%;
    position: relative;
    left: 0;
    background-color: black;
}