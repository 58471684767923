.demo-frame {
    height: 350px;
    width: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 0 !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.active-demo {
    height: 450px;
}

.full-screen {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    height: 100%;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.45);
}

.demo-frame iframe {
    width: 100%;
    height: 90% !important;
    min-height: 90% !important;
    padding: 0;
    border-radius: 10px;

}

.demo-thumbnail {
    object-fit: cover;
}

.demo-frame img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.try-demo-text {
    position: absolute;
    margin-bottom: 100px;
    color: white;
    font-size: 25px;

}

.play-button {
    position: absolute;
    z-index: 1;
    border: 0;
    background-color: transparent;
    border: none;
    height: 50px;
    width: 55px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s linear;
}

.play-button:hover {
    opacity: 0.2;
}

.play-button img {
    height: 100%;
    width: 100%;
}

.demo-controls {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;


}

.demo-controls button {
    height: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'BebasNeue';
    font-size: 25px;
    background-color: #222023;
}

.demo-controls button:hover {
    filter: brightness(1.1);
}

.exit-button {
    width: 90%;
    margin-right: 5px;
    background-color: #c71717 !important;
    color: white;


}

.full-screen-button {
    width: 10%;
    margin-left: 5px;
    background-color: darkgray !important;


}

.demo-controls button img {
    height: 30px;
    width: 30px;
}